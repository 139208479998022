import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

export default () => {
  useEffect(() => {
    navigate(
      'https://dashboard.paymongo.com/activate?utm_source=google_messages&utm_medium=sms_campaign&utm_name=sms_01'
    );
  }, []);

  return <div />;
};
